<template>
  <div>

    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">账户安全</div>
      <div class="account-card-header"></div>
      <div class="account-card-body">
        <div class="account-row">
          <div class="icon lock"></div>
          <div class="main">
            <div class="title">账号密码</div>
            <div class="desc">用于保护帐号信息和登录安全</div>
          </div>
          <div class="btn" @click="hiddenPwdReset = false">修改</div>
        </div>
      </div>
    </menu-wrapper>

    <!-- 密码重置 -->
    <password-reset :hidden.sync="hiddenPwdReset"></password-reset>
    <!-- 邮箱重置 -->
    <email-reset
      :hidden.sync="hiddenEmailReset"
      @change="getAccountInfo"
    ></email-reset>
    <!-- 手机号重置 -->
    <phone-reset
      :hidden.sync="hiddenPhoneReset"
      @change="getAccountInfo"
    ></phone-reset>

    
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import passwordReset from "@/components/user/security/password-reset.vue";
import emailReset from "@/components/user/security/email-reset.vue";
import phoneReset from "@/components/user/security/phone-reset.vue";

import centerConfig from "@/common/config/user/center.config.js";

export default {
  components: {
    menuWrapper,
    passwordReset,
    emailReset,
    phoneReset,
  },
  data() {
    return {
      ...centerConfig,
      hiddenPwdReset: true,
      hiddenEmailReset: true,
      hiddenPhoneReset: true,
      accountInfo: {
          securityLevel:0,
          score:0
      },
    };
  },
  onLoad(e) {
    if (e.service) this[`hidden${e.service}Reset`] = false;

    this.getAccountInfo();
  },
  methods: {
    getAccountInfo() {
      this.$axios.get("/api/User/GetPersonalCenter","" )
            .then(res => {
				this.accountInfo=res.data
            })
            .catch(err => {
              return Promise.reject(err);
            });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-card-header {
  @extend %flex-align-center;
  padding: 30px 0;

  .title {
    font-size: 20px;
    color: #333;
    // font-weight: bold;
  }

  .score {
    margin-left: 15px;
    @extend %flex-align-center;

    .value {
      font-size: 20px;
      font-weight: bold;
    }

    .unit {
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .score-slider {
    width: 360px;
    margin-left: 5px;

    ::v-deep .uni-slider-handle-wrapper {
      height: 4px;
      cursor: default;

      .uni-slider-handle {
        cursor: default;
      }
    }
  }
}

.account-card-body {
  .account-row {
    padding: 30px 0;
    border-top: 1px solid $border-color;
    @extend %flex-align-center;

    .icon {
      width: 40px;
      height: 40px;
      background: url("https://account.xiaomi.com/static/res/b52bef2/account-static/respassport/acc-2014/img/device-icos.png")
        no-repeat;

      &.email {
        background-position: -40px 0;
      }

      &.phone {
        background-position: -200px 0;
      }
    }

    .main {
      margin-left: 15px;

      .title {
        color: #555;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
      }

      .desc {
        color: #9d9d9d;
        line-height: 1.5;
      }
    }

    .btn {
      margin-left: auto;
      color: white;
      border: 1px solid var(--ThemeColor,#ff6701);
      padding: 7px 40px;
      background-color: var(--ThemeColor,#ff6701);
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
