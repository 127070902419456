<template>
  <zw-dialog
    :hidden="hidden"
    title="修改安全邮箱"
    :width="450"
    :height="300"
    :showHeaderBg="false"
    borderRadius
    @close="closeDialog"
  >
    <div class="form-wrap">
      <!-- 步骤条 -->
      <!-- <steps :steps="['输入新邮箱', '验证新邮箱', '完成']" :step="step"></steps> -->

      <!-- 输入新邮箱表单 -->
      <div v-if="step == 0" @keydown.enter="goStep2">
        <div class="form-item-title">请输入新的安全邮箱地址</div>
        <div class="form-item">
          <input placeholder="请输入邮箱" v-model="email" />
        </div>
        <div class="form-item">
          <!-- <input placeholder="图片验证码" v-model="captcha" maxlength="4" />
          <img
            class="captcha"
            :src="`${$target}/security/captcha?key=${random}`"
            title="点击刷新"
            @click="getRandom"
          /> -->
        </div>
        <!-- <div class="form-item btn-wrap">
          <button class="btn-primary" @click="goStep2">下一步</button>
        </div> -->
         <div class="form-item btn-wrap">
          <button class="btn-primary" @click="submit">确定</button>
          <button class="btn-gray" @click="step = 0">取消</button>
        </div>
      </div>
      <!-- 验证新邮箱表单 -->
      <!-- <div v-if="step == 1" @keydown.enter="submit">
        <div class="form-item-title small">
          <div class="email-wrap">
            我们向<text class="email elip">{{ email }}</text
            >发送了验证邮件
          </div>
          <div>请输入邮件中的验证码</div>
        </div>
        <div class="form-item">
          <input
            placeholder="请输入验证码"
            v-model="code"
            maxlength="6"
            type="number"
          />
          <div
            :class="['btn-send', { disabled: sendBtn.disabled }]"
            @click="sendEmail"
          >
            {{ sendBtn.text }}
          </div>
        </div>
        <div class="form-item btn-wrap">
          <button class="btn-primary" @click="submit">确定</button>
          <button class="btn-gray" @click="step = 0">取消</button>
        </div>
      </div> -->
      <!-- 验证新邮箱表单 -->
      <!-- <div v-if="step == 2">
        <div class="form-item-title tip">您已成功修改安全邮箱！</div>
        <div class="form-item btn-wrap">
          <button class="btn-primary" @click="closeDialog">返回我的账号</button>
        </div>
      </div>-->
    </div> 
  </zw-dialog>
</template>

<script>
import steps from "@/components/common/steps.vue";

export default {
  components: {
    // "steps":steps,
  },
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      step: 0,
      email: "",
      captcha: "",
      code: "",
      sendBtn: {
        text: "重新发送",
        disabled: false,
      },
      random: "",
    };
  },
  created() {
    this.getRandom();
  },
  watch: {
    hidden(e) {
      if (e) {
        this.step = 0;
        this.email = this.captcha = this.code = "";
      }
    },
  },
  methods: {
    getRandom() {
      this.random = this.$util.getRandomStr();
    },
    closeDialog() {
      this.$emit("update:hidden", true);
    },
    goStep2() {
      // 校验邮箱格式
      if (!this.$regular.email.test(this.email)) {
        return this.$util.showErrorMsg("请输入正确的邮箱");
      }

      // 校验图片验证码格式
      if (this.captcha.length != 4) {
        return this.$util.showErrorMsg("验证码错误");
      }

      // 发送邮件，成功则跳转到第二步
      this.sendCaptcha(true);
    },
    sendCaptcha(goNext = false) {
      clearInterval(this.timer);
      this.sendBtn.disabled = true;
      this.sendBtn.text = "发送中...";

      // uni.showLoading({
      // 	title: '发送邮件中...',
      // 	mask: true
      // });
      this.$util.request(
        "/security/sendCaptchaForBindEmail",
        {
          email: this.email,
          captcha: this.captcha,
          key: this.random,
        },
        (res) => {
          // uni.hideLoading();

          if (res.state == "ok") {
            if (goNext) this.step = 1;

            let seconds = 60;
            this.timer = setInterval(() => {
              if (seconds <= 0) {
                clearInterval(this.timer);
                this.sendBtn.disabled = false;
                this.sendBtn.text = "重新发送";
              } else {
                this.sendBtn.text = `重新发送（${seconds}）`;
                seconds--;
              }
            }, 1000);
          } else {
            this.$util.showErrorMsg(res.msg);
            this.getRandom();
            this.captcha = "";
            this.code = "";

            this.sendBtn.disabled = false;
            this.sendBtn.text = "重新发送";

            this.step = 0;
          }
        }
      );
    },
    sendEmail() {
      if (this.sendBtn.disabled) return;

      this.sendCaptcha();
    },
    submit() {
      // 校验验证码
      if (!this.$regular.captcha.test(this.code)) {
        return this.$util.showErrorMsg("验证码错误");
      }

      // uni.showLoading({
      // 	title: '处理中...',
      // 	mask: true
      // });
      this.$util.request(
        "/security/updateEmail",
        {
          email: this.email,
          captcha: this.code,
        },
        (res) => {
          // uni.hideLoading();

          if (res.state == "ok") {
            this.$emit("change");

            this.step = 2;
          } else {
            this.$util.showErrorMsg(res.msg);
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped >
.form-wrap {
  width: 100%;
  margin: 0 20px;
  padding: 30px 40px 0;
  border-top: 1px solid $border-color;

  .form-item-title {
    font-size: 15px;
    color: #555;
    font-weight: bold;
    line-height: 30px;

    &.small {
      color: #333333;
      margin-bottom: 20px;

      .email-wrap {
        @extend %flex-align-center;

        .email {
          display: inline-block;
          margin: 0 5px;
          color: $color-primary;
          max-width: 170px;
        }
      }
    }

    &.tip {
      color: #333333;
      text-align: center;
      padding: 10px;
    }
  }

  .form-item {
    @extend %flex-align-center;
    margin-bottom: 10px;

    input {
      flex: 1;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      color: #333;
      border: 1px solid #e8e8e8;
    }

    .captcha {
      width: 125px;
      height: 42px;
      margin-left: 15px;
      cursor: pointer;
    }

    .btn-send {
      border: 1px solid $border-color;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: #ececec;
        color: #888;
      }

      &.disabled {
        cursor: default;
        color: #757575;

        &:hover {
          cursor: default;
          color: #757575;
          background-color: $bg-color;
        }
      }
    }

    &.btn-wrap {
      margin-top: 25px;
      justify-content: center;

      %btn {
        width: 122px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin: 0 5px;
      }

      .btn-primary {
        @extend %btn;
        color: #ffffff;
        background-color: $color-primary;
      }

      .btn-gray {
        @extend %btn;
        color: #555;
        background-color: #ffffff;
        border: 1px solid $border-color;
      }
    }
  }
}
</style>
