<template>
  <zw-dialog
    :hidden="hidden"
    title="修改密码"
    :width="450"
    :height="320"
    :showHeaderBg="false"
    :hideFooter="false"
    :showFooterBg="false"
    borderRadius
    @close="closeDialog"
    @cancel="closeDialog"
    @confirm="submit"
  >
    <div class="form-wrap" @keydown.enter="submit">
      <div class="form-item-title">原密码</div>
      <div class="form-item">
        <el-input
          placeholder="输入原密码"
          :type="selInputId == 1 ? showType:hiddenType "
          v-model="oldPassword"
          ref="oldPassword"
          auto-complete="new-password"
        >
          <template>
            <span
              slot="suffix"
              :class="selInputId == 1 ? hiddenIcon:showIcon "
              @mouseover="mouseover(1)"
              @mouseleave="mouseleave()"
            ></span>
          </template>
        </el-input>
      </div>
      <div class="form-item-title">新密码</div>
      <div class="form-item">
        <el-input
          placeholder="输入新密码"
          :type="selInputId == 2 ? showType:hiddenType"
          v-model="newPassword"
          ref="newPassword"
          ><template>
            <span
              slot="suffix"
              :class="selInputId == 2 ? hiddenIcon:showIcon "
              @mouseover="mouseover(2)"
              @mouseleave="mouseleave()"
            ></span>
          </template>
        </el-input>
      </div>
      <div class="form-item-title">确认密码</div>
      <div class="form-item">
        <el-input
          placeholder="重复新密码"
          :type="selInputId == 3 ? showType:hiddenType"
          v-model="repeatPassword"
          ref="repeatPassword"
          ><template>
            <span
              slot="suffix"
              :class="selInputId == 3 ? hiddenIcon:showIcon "
              @mouseover="mouseover(3)"
              @mouseleave="mouseleave()"
            ></span>
          </template>
        </el-input>
      </div>
      <!-- <div class="form-item-title">验证码</div>
      <div class="form-item">
        <input placeholder="图片验证码" maxlength="4" v-model="captcha" />
        <img
          class="captcha"
          :src="`${$target}/security/captcha?key=${random}`"
          title="点击刷新"
          @click="getRandom"
        />
      </div> -->
    </div>
  </zw-dialog>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      captcha: "",
      random: "",
      showType: "text",
      hiddenType: "password",
      showIcon: "el-icon-view",
      hiddenIcon: "el-input__icon el-icon-loading",
      selInputId: 0,
      iconLoading: false,
    };
  },
  watch: {
    hidden() {
      if (!this.hidden) {
        // this.getRandom();
      }
    },
  },
  methods: {
    mouseover(e) {
      this.selInputId = e;
      this.iconLoading = true;
    },
    mouseleave(e) {
      this.selInputId = 0;
      this.iconLoading = false;
    },
    getRandom() {
      this.random = "12345"; //this.$util.getRandomStr();
    },
    closeDialog() {
      this.$emit("update:hidden", true);
    },
    submit() {
      if (!this.checkForm()) return;
      const reqData = {
        OldPassword: this.oldPassword,
        NewPassword: this.newPassword,
        NewPasswordAgain: this.repeatPassword,
      };
      this.$axios
        .post("/api/User/UpdatePassword", reqData)
        .then((res) => {
          if (res.data) {
            this.closeDialog();
            this.notifySucceed("修改成功");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    checkForm() {
      //!this.$regular.password.test(this.oldPassword)
      if (this.oldPassword.length<7 || this.oldPassword.length>50) {
        return this.notifyError("原密码格式错误");
      }
      if (!this.$regular.password.test(this.newPassword)) {
        return this.notifyError("新密码为字母开头,长度6-18之间,允许字母数字和下划线");
      }
      if (this.repeatPassword != this.newPassword) {
        return this.notifyError("新密码输入不一致");
      }
      // if (this.captcha.length != 4) {
      //   return this.$util.showErrorMsg("验证码错误");
      // }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  width: 100%;
  margin: 0 20px;
  padding: 30px 40px 0;
  border-top: 1px solid $border-color;

  .form-item-title {
    font-size: 15px;
    color: #555;
    // font-weight: bold;
    line-height: 30px;
  }

  .form-item {
    @extend %flex-align-center;
    margin-bottom: 10px;

    input {
      flex: 1;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      color: #333;
      border: 1px solid #e8e8e8;
    }

    .captcha {
      width: 125px;
      height: 42px;
      margin-left: 15px;
      cursor: pointer;
    }
    & span {
      font-size: 24px;

      .el-icon-view {
        padding-top: 25%;
      }
    }
  }
}
</style>
